import { css } from '@emotion/react'
import "focus-visible/dist/focus-visible"

import { mediaStyle } from './theme'

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Dokdo&family=Noto+Serif+TC:wght@300;400;500;600;700;900&display=swap');
  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
  }

  body {
    min-width: 100%;
    min-height: 100%;
  }

  ::selection {
    color: #fff;
    background: black;
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  ${mediaStyle}


main,nav {
  display: block
}

progress {
  display: inline-block;
  vertical-align: baseline
}

a {
  background-color: transparent
}

a:active {
  outline: 0
}

a,a:focus,a:hover,a:visited {
  text-decoration: none
}

abbr[title] {
  border-bottom: 1px dotted
}

b,strong {
  font-weight: 700
}

dfn {
  font-style: italic
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 0
}

hr {
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

code,kbd,pre,samp {
  font-size: 1em
}

button,input,optgroup,select,textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button,select {
  text-transform: none
}

button,html input[type=button],input[type=reset],input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled],html input[disabled] {
  cursor: default
}

button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox],input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

#comments .submit,.search .search-submit {
  padding: 10px 15px;
  border-radius: 2px;
  line-height: 1.85714285714286;
  border: 0
}

.search .site-main .ast-search-submit {
  display: none
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid #eaeaea;
  margin: 0 0;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0
}

fieldset legend {
  margin-bottom: 1.5em;
  padding: 0 .5em
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: 700
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td,th {
  padding: 0
}

h1,h2,h3,h4,h5,h6 {
  clear: both
}

.entry-content h1 {
  font-size: 2.25em;
  line-height: 1.2
}

.entry-content h2 {
  font-size: 2.25em;
  line-height: 1.3;
}

.entry-content h3 {
  font-size: 1.75em;
  line-height: 1.4;
}

.entry-content h4 {
  line-height: 1.5;
  font-size: 1.5em;
}

.entry-content h5 {
  line-height: 1.6;
  font-size: 1.25em;
}

.entry-content h6 {
  line-height: 1.7;
  font-size: 1em;
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6, .entry-content pre {
  margin-bottom: 20px;
}

.entry-content ol,
.entry-content ul {
  margin: 0 0 1.5em 3em;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

body:not(.logged-in) {
  position: relative
}

.site-title {
  font-weight: 400
}

.site-description,.site-title {
  margin-bottom: 0
}

.site-description a,.site-description:focus a,.site-description:hover a,.site-title a,.site-title:focus a,.site-title:hover a {
  transition: all .2s linear
}

.site-title a,.site-title a:focus,.site-title a:hover,.site-title a:visited {
  color: #222
}

.site-description a,.site-description a:focus,.site-description a:hover,.site-description a:visited {
  color: #999
}

.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
  color: #21759b;
  display: block;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: 0;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em
}

.site .skip-link:focus {
  clip: auto;
  height: auto;
  left: 6px;
  top: 7px;
  width: auto;
  z-index: 100000;
  outline: thin dotted
}

.logged-in .site .skip-link {
  box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
  font-family: "Open Sans",sans-serif
}

input,select {
  line-height: 1
}

.site-title a {
  line-height: 1.2
}

.site-header .site-description {
  line-height: 1.5
}

.ast-single-post .entry-title,.ast-single-post .entry-title a {
  line-height: 1.2
}

.entry-title,.entry-title a {
  font-weight: 400
}

#page {
  display: block
}

#primary,#secondary {
  width: 100%
}

#primary {
  margin: 4em 0
}

#site-navigation {
  height: 100%
}

.post-navigation a {
  background: 0 0;
  font-size: 16px;
  font-size: 1.06666rem;
  padding: 0 1.5em;
  height: 2.33333em;
  line-height: calc(2.33333em - 3px)
}

.sticky {
  display: block
}

.hentry {
  margin: 0 0 1.5em
}

.page-links {
  clear: both;
  margin-top: 1em
}

.page-links a {
  display: inline-block
}

.page-links a .page-link {
  border-color: #eaeaea;
  background: 0 0
}

.page-links .page-link {
  padding: 0;
  margin: 0 0 .3em .3em;
  border: 2px solid #eaeaea;
  color: #000;
  background: 0 0;
  font-size: .8em;
  width: 2.5em;
  height: 2.5em;
  line-height: calc( 2.5em - 4px);
  display: inline-block;
  text-align: center;
  transition: all .2s linear
}

.page-links .page-link:focus,.page-links .page-link:hover {
  color: #000
}

@media (max-width: 768px) {
  .page-links .page-link {
      margin-bottom:6px
  }
}

.entry-content>:last-child,.entry-summary>:last-child,.page-content>:last-child {
  margin-bottom: 0
}

.blocks-gallery-grid,.wp-block-gallery {
  margin: 0
}

.wp-block-separator {
  max-width: 100px
}

.wp-block-separator.is-style-wide {
  max-width: none
}

.entry-content .has-2-columns .wp-block-column:first-child {
  padding-right: 10px
}

.entry-content .has-2-columns .wp-block-column:last-child {
  padding-left: 10px
}

@media (max-width: 782px) {
  .entry-content .wp-block-columns .wp-block-column {
      flex-basis:100%
  }

  .entry-content .has-2-columns .wp-block-column:first-child {
      padding-right: 0
  }

  .entry-content .has-2-columns .wp-block-column:last-child {
      padding-left: 0
  }
}

body {
  overflow-x: hidden
}

body .entry-content .wp-block-latest-posts {
  margin-left: 0
}

body .entry-content .wp-block-latest-posts li {
  list-style: none
}


.entry-content .blocks-gallery-item img {
  margin-bottom: auto
}

.wp-block-pullquote {
  border-top: 4px solid #555d66;
  border-bottom: 4px solid #555d66;
  color: #40464d
}


.site-header-section {
  height: 100%;
  min-height: 0;
  align-items: center
}

.site-header-section .ast-main-header-bar-alignment {
  height: 100%
}

.site-header-section>* {
  padding: 0 10px
}

.site-header-section>div:first-child {
  padding-left: 0
}

.site-header-section>div:last-child {
  padding-right: 0
}

.site-header-section .ast-builder-menu {
  align-items: center
}

.ast-builder-layout-element.ast-header-search {
  height: auto
}

input[type=button],input[type=button]:focus,input[type=button]:hover,input[type=reset],input[type=reset]:focus,input[type=reset]:hover,input[type=submit],input[type=submit]:focus,input[type=submit]:hover {
  box-shadow: none
}

textarea {
  width: 100%
}

input[type=search]:focus {
  outline: thin dotted
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 5.7px 0;
  padding: 0;
  border: none
}

input[type=range]:focus {
  outline: 0
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.6px;
  cursor: pointer;
  box-shadow: 2.6px 2.6px .4px #ccc,0 0 2.6px #d9d9d9;
  background: rgba(255,255,255,.2);
  border-radius: 13.6px;
  border: 0 solid #fff
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 rgba(255,221,0,.37),0 0 0 rgba(255,224,26,.37);
  border: 7.9px solid #0274be;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #0274be;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.7px
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(255,255,255,.2)
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.6px;
  cursor: pointer;
  box-shadow: 2.6px 2.6px .4px #ccc,0 0 2.6px #d9d9d9;
  background: rgba(255,255,255,.2);
  border-radius: 13.6px;
  border: 0 solid #fff
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 rgba(255,221,0,.37),0 0 0 rgba(255,224,26,.37);
  border: 7.9px solid #0274be;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #0274be;
  cursor: pointer
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.6px;
  cursor: pointer;
  background: 0 0;
  border-color: transparent;
  color: transparent
}

input[type=range]::-ms-fill-lower {
  background: rgba(199,199,199,.2);
  border: 0 solid #fff;
  border-radius: 27.2px;
  box-shadow: 2.6px 2.6px .4px #ccc,0 0 2.6px #d9d9d9
}

input[type=range]::-ms-fill-upper {
  background: rgba(255,255,255,.2);
  border: 0 solid #fff;
  border-radius: 27.2px;
  box-shadow: 2.6px 2.6px .4px #ccc,0 0 2.6px #d9d9d9
}

input[type=range]::-ms-thumb {
  box-shadow: 0 0 0 rgba(255,221,0,.37),0 0 0 rgba(255,224,26,.37);
  border: 7.9px solid #0274be;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #0274be;
  cursor: pointer;
  height: 8.6px
}

input[type=range]:focus::-ms-fill-lower {
  background: rgba(255,255,255,.2)
}

input[type=range]:focus::-ms-fill-upper {
  background: rgba(255,255,255,.2)
}

input[type=color] {
  border: none;
  width: 100px;
  padding: 0;
  height: 30px;
  cursor: pointer
}

input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none
}

input[type=color]::-webkit-color-swatch {
  border: none
}

.page .entry-header {
  margin-bottom: 1.5em
}

.entry-content {
  word-wrap: break-word
}

.entry-content p {
  margin-bottom: 1.6em
}

.read-more {
  margin-bottom: 0
}

.archive .entry-title,.blog .entry-title,.search .entry-title {
  line-height: 1.3
}

.archive .format-aside .entry-title,.archive .format-status .entry-title,.blog .format-aside .entry-title,.blog .format-status .entry-title {
  display: none
}

.page-title {
  margin-bottom: 1em;
  font-weight: 400
}

.entry-title {
  margin-bottom: .2em
}

.single .entry-header {
  margin-bottom: 2em
}

.single .post-navigation {
  margin: 0;
  padding: 2em 0 0;
  border-top: 1px solid #eee
}

@media (max-width: 768px) {
  .single .post-navigation {
      padding-top:1.5em
  }
}

.single .post-navigation a {
  margin: 2px;
  display: inline-block;
  text-align: center;
  color: #000
}

@media (min-width: 421px) {
  .single .post-navigation .nav-links {
      -js-display:flex;
      display: flex
  }

  .single .post-navigation .nav-next,.single .post-navigation .nav-previous {
      flex: auto
  }
}

@media (max-width: 420px) {
  .single .post-navigation .ast-left-arrow,.single .post-navigation .ast-right-arrow {
      display:none
  }

  .single .post-navigation .nav-next,.single .post-navigation .nav-previous {
      width: 100%
  }

  .single .post-navigation .nav-next a,.single .post-navigation .nav-previous a {
      width: 100%
  }

  .single .post-navigation .nav-previous {
      margin-bottom: 1em
  }
}

.single .entry-header .ast-single-post-order+.post-thumb img {
  margin-top: 2em;
  margin-bottom: 0
}

.single .entry-header.ast-no-title .post-thumb+.ast-single-post-order {
  margin-top: 0
}

.single .entry-header .post-thumb+.ast-single-post-order {
  margin-top: 2em
}

.single .entry-header .post-thumb img {
  margin-top: 0;
  margin-bottom: 0
}

.page .has-post-thumbnail .post-thumb img {
  margin-bottom: 1.5em
}

.post-password-form {
  text-align: center
}

@media (max-width: 420px) {
  .post-password-form input[type=password] {
      display:block;
      margin: 10px auto
  }
}

.post-password-form input[type=submit] {
  padding: 10px 20px;
  border-radius: 2px
}

`;
