import React, { useState } from 'react';
import { Global } from '@emotion/react'
import { ChakraProvider, extendTheme, ColorModeScript, withDefaultColorScheme } from "@chakra-ui/react"

import globalStyles from './global-styles';

import theme, { MediaContextProvider } from './theme';
import CustomThemeContext from './customThemeContext';

const ReThemeProvider = ({ children }) => (
  <CustomThemeContext.Consumer>
    {({ customTheme }) => (
      <ChakraProvider
        theme={extendTheme(
          theme,
          withDefaultColorScheme({ colorScheme: 'blackAlpha' }),
          customTheme,
        )}
        resetCSS
      >
        <MediaContextProvider>
          <>
            <ColorModeScript initialColorMode="light" />
            <Global styles={globalStyles} />
            {children}
          </>
        </MediaContextProvider>
      </ChakraProvider>
    )}
  </CustomThemeContext.Consumer>
);

const withCustomTheme = SubComp => props => {
  const [customTheme, setCustomTheme] = useState({})
  return (
    <CustomThemeContext.Provider value={{ customTheme, setCustomTheme }}>
      <SubComp {...props} />
    </CustomThemeContext.Provider>
  )
}

export default withCustomTheme(ReThemeProvider)
