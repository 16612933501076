import React, { Fragment } from 'react'
import { StateMachineProvider, createStore } from 'little-state-machine'

import ThemeProvider from './src/components/ThemeProvider'
import HeaderProvider from './src/contexts/header/Provider'
import FooterProvider from './src/contexts/footer/Provider'
import FirebaseProvider from './src/firebase/provider'
import FirebaseSDK from './src/firebase/sdk'

createStore({
  quiz: {},
});

const Fp = typeof window === 'undefined' ? Fragment : FirebaseProvider
const SDK = typeof window === 'undefined' ? Fragment : FirebaseSDK

const Providers = ({ element }) =>
  <ThemeProvider>
    <StateMachineProvider>
      <Fp>
        <SDK>
          <HeaderProvider>
            <FooterProvider>
              {element}
            </FooterProvider>
          </HeaderProvider>
        </SDK>
      </Fp>
    </StateMachineProvider>
  </ThemeProvider>

export default Providers
