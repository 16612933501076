exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-q-baron-cohen-index-js": () => import("./../../../src/pages/q/baron-cohen/index.js" /* webpackChunkName: "component---src-pages-q-baron-cohen-index-js" */),
  "component---src-pages-q-love-languages-index-js": () => import("./../../../src/pages/q/love-languages/index.js" /* webpackChunkName: "component---src-pages-q-love-languages-index-js" */),
  "component---src-pages-q-love-languages-lover-dual-accept-js": () => import("./../../../src/pages/q/love-languages/lover/dual/accept.js" /* webpackChunkName: "component---src-pages-q-love-languages-lover-dual-accept-js" */),
  "component---src-pages-q-love-languages-lover-dual-index-js": () => import("./../../../src/pages/q/love-languages/lover/dual/index.js" /* webpackChunkName: "component---src-pages-q-love-languages-lover-dual-index-js" */),
  "component---src-pages-q-love-languages-lover-index-js": () => import("./../../../src/pages/q/love-languages/lover/index.js" /* webpackChunkName: "component---src-pages-q-love-languages-lover-index-js" */),
  "component---src-pages-q-love-languages-lover-invitation-js": () => import("./../../../src/pages/q/love-languages/lover/invitation.js" /* webpackChunkName: "component---src-pages-q-love-languages-lover-invitation-js" */),
  "component---src-pages-q-xiahai-flower-index-js": () => import("./../../../src/pages/q/xiahai-flower/index.js" /* webpackChunkName: "component---src-pages-q-xiahai-flower-index-js" */),
  "component---src-pages-q-xiahai-flower-play-js": () => import("./../../../src/pages/q/xiahai-flower/play.js" /* webpackChunkName: "component---src-pages-q-xiahai-flower-play-js" */),
  "component---src-pages-q-xiahai-flower-result-js": () => import("./../../../src/pages/q/xiahai-flower/result.js" /* webpackChunkName: "component---src-pages-q-xiahai-flower-result-js" */),
  "component---src-quizes-love-languages-calculate-js": () => import("./../../../src/quizes/love-languages/calculate.js" /* webpackChunkName: "component---src-quizes-love-languages-calculate-js" */),
  "component---src-quizes-love-languages-question-js": () => import("./../../../src/quizes/love-languages/question.js" /* webpackChunkName: "component---src-quizes-love-languages-question-js" */),
  "component---src-quizes-love-languages-result-dual-js": () => import("./../../../src/quizes/love-languages/result/dual.js" /* webpackChunkName: "component---src-quizes-love-languages-result-dual-js" */),
  "component---src-quizes-love-languages-result-index-js": () => import("./../../../src/quizes/love-languages/result/index.js" /* webpackChunkName: "component---src-quizes-love-languages-result-index-js" */),
  "component---src-quizes-love-languages-result-invite-js": () => import("./../../../src/quizes/love-languages/result/invite.js" /* webpackChunkName: "component---src-quizes-love-languages-result-invite-js" */),
  "component---src-quizes-love-languages-share-js": () => import("./../../../src/quizes/love-languages/share.js" /* webpackChunkName: "component---src-quizes-love-languages-share-js" */),
  "component---src-quizes-love-languages-start-js": () => import("./../../../src/quizes/love-languages/start.js" /* webpackChunkName: "component---src-quizes-love-languages-start-js" */),
  "component---src-templates-baron-cohen-result-js": () => import("./../../../src/templates/baron-cohen-result.js" /* webpackChunkName: "component---src-templates-baron-cohen-result-js" */),
  "component---src-templates-baron-cohen-test-js": () => import("./../../../src/templates/baron-cohen-test.js" /* webpackChunkName: "component---src-templates-baron-cohen-test-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

