import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm, useFormContext, useFormState } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { navigate } from 'gatsby-link';
import { Flex } from '@chakra-ui/layout';

import ThemeController from './ThemeController';

function updateAction(state, payload) {
  return Object.assign(state, { quiz: payload });
}

const FormController = ({ children, quiz, type: defaultType, pageContext }) => {
  const { actions, state } = useStateMachine({ updateAction }, { name: `superquiz` });
  const methods = useForm({ defaultValues: {
    ...state.quiz?.[quiz]?.[pageContext.id],
    type: defaultType,
  } });
  const { isSubmitting } = useFormState({ control: methods.control });
  const navigateRef = useRef()
  const nextLink = pageContext.next ? `${pageContext.next.startsWith('/') ? '' : '/'}${pageContext.next}` : ''
  const onSubmit = useCallback(data => {
    const d = pageContext.id ? { [pageContext.id]: data } : data
    actions.updateAction({ [quiz]: d });
    if (pageContext.next && !pageContext.isVirtual) {
      // let targetLink = nextLink.replace(/{{([^}]+)}}/g, (match, key) => data[key])
      // if (pageContext.qId && pageContext.isLast) {
      //   if (data.invitationId) {
      //     targetLink = `/q/love-languages/lover/dual/accept/`
      //   }
      // }
      navigateRef.current = setTimeout(() => {
        navigate(pageContext.next)
      }, 500)
    }
  }, [nextLink, pageContext, quiz])
  useEffect(() => {
    if (navigateRef.current) clearTimeout(navigateRef.current)
  }, [children])
  const type = methods.watch('type')
  // const formData = methods.watch()
  return (
    <>
      {useMemo(() => pageContext.next || pageContext.withForm ? (
        <FormProvider {...methods}>
          <Flex flex="1" flexDirection="column" as="form" onSubmit={methods.handleSubmit(isSubmitting ? () => {} : onSubmit)}>
            {children}
            <input type="submit" tabIndex="-1" style={{ opacity: 0, width: 0, height: 0 }} />
          </Flex>
        </FormProvider>
      ) : children, [children, isSubmitting, methods, onSubmit])}
      <ThemeController type={type} quiz={quiz} />
    </>
  )
}

export default FormController

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();
  return children({ ...methods });
};
