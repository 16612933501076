import { useTheme } from '@chakra-ui/system';
import { get, mapValues } from 'lodash';
import { useContext, useEffect } from 'react'
import customThemeContext from '../../components/ThemeProvider/customThemeContext'

const customStyles = {
  'love-languages': {
    '*': {
      bg: 'beige',
      header: 'headerYellow',
      text: 'brown',
      footer: 'yellow'
    },
    'lover': {
      header: 'pink',
      footer: 'footerPink',
      bg: 'lightPink',
      qNumber: 'darkRed',
      text: 'deepRed',
      primary: 'red',
      resultBg: 'pink',
      title: 'shiningPink',
      border: 'borderRed',
      desc: 'descRed'
    },
    'friend': {
      header: 'resultFriendBg',
      footer: 'footerGreen',
      bg: 'lightGreen',
      qNumber: 'darkGreen',
      text: 'deepGreen',
      primary: 'green',
      resultBg: 'resultFriendBg',
      title: 'shiningGreen',
      border: 'borderGreen',
      desc: 'descGreen'
    },
  }
}

const getTheme = ({ bg, qNumber, header, footer, text, primary, resultBg, title, border, desc }) => (
  {
    styles: {
      global: {
        body: {
          bg,
        },
        '#header': {
          bg: header
        },
        '#footer': {
          bg: footer
        },
      }
    },
    colors: {
      love: {
        bg,
        header,
        text,
        primary,
        resultBg,
        title,
        qNumber,
        border,
        desc,
        scheme: {
          500: primary,
        }
      }
    },
    components: {
      Input: {
        defaultProps: {
          variant: "clean",
        },
        variants: {
          clean: {
            border: "none",
            rounded: 'lg',
          },
        }
      }
    }
  }
)

const ThemeController = ({ type, quiz, children }) => {
  const { colors: { love: loveColors } } = useTheme()
  const { setCustomTheme } = useContext(customThemeContext)
  useEffect(() => {
    let theme = {}
    if (quiz) {
      const themeCfg = get(customStyles, [quiz, type], customStyles[quiz]?.['*'])
      theme = getTheme(mapValues(themeCfg, k => loveColors[k]))
    }
    setCustomTheme(theme)
  }, [quiz, type])
  return children || null
}

export default ThemeController
