import { getFirestore } from '@firebase/firestore';
import { getFunctions } from '@firebase/functions';
import React from 'react'
import { FirestoreProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';

const FirebaseSDK = ({ children }) => {
  const app = useFirebaseApp()

  return (
    <FirestoreProvider sdk={getFirestore(app)}>
      <FunctionsProvider sdk={getFunctions(app)}>
        {children}
      </FunctionsProvider>
    </FirestoreProvider>
  )
}

export default FirebaseSDK
