import React from 'react'
import { FirebaseAppProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: "AIzaSyAEf87txZ-GGoDUyo8qxBWZAT9Btff7-jQ",
  authDomain: "superquiz-81c29.firebaseapp.com",
  projectId: "superquiz-81c29",
  storageBucket: "superquiz-81c29.appspot.com",
  messagingSenderId: "43194956276",
  appId: "1:43194956276:web:9ba9500221421689df21a1"
};

const Provider = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    {children}
  </FirebaseAppProvider>
)

export default Provider
