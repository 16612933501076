import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, withPrefix, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';
import { Flex } from '@chakra-ui/layout';

import { responsive } from '../../components/ThemeProvider/theme';
import useHeader from '../../contexts/header/useHeader'
import useFooter from '../../contexts/footer/useHeader';

import Header from '../Header'
import Footer from '../Footer';
import FormController from './FormController';
import ThemeController from './ThemeController';

const logos = {
  'love-languages': <StaticImage src="../love-logo.png" alt="愛之語測驗" />,
  'xiahai-flower': <StaticImage src="../flower-logo.png" alt="叢心出花測驗" />
}

const headerProps = {
  'xiahai-flower': {
    justifyContent: 'center'
  }
}

const footerProps = {
  'love-languages': {
    color: 'black',
  },
  'xiahai-flower': {
    // bg: 'white',
    py: '0.75rem',
    fontSize: responsive('0.75em', '1em'),
    id: 'footer-xiahai'
  }
}

const Layout = ({ children, location, pageContext }) => {
  const { hideHeader, headerHeight } = useHeader()
  const { hideFooter } = useFooter()
  const q = useMemo(() => {
    if (!location) return null
    const res = /q\/([^/]+)\/?([^/]*)/.exec(location.pathname)
    return res && res.slice(1, 3)
  }, [location])
  const isResult = location && /result|invite/.exec(location.pathname)
  const { site: { siteMetadata: { title, description } } } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)
  return (
    <>
      <Helmet
        defaultTitle={title}
        titleTemplate={`%s - ${title}`}
      >
        <html lang="zh-Hant-TW" />
        <meta name="description" content={description} />
        <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/apple-touch-icon.png')} />
        <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicon-32x32.png')} />
        <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicon-16x16.png')} />
        <link rel="mask-icon" color="#5bbad" href={withPrefix('/safari-pinned-tab.svg')} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content="index,follow" />
        {pageContext?.title && <title>{pageContext.title}</title>}
      </Helmet>

      {!hideHeader && <Header height="headerHeight" hideLinks={Boolean(q)} {...headerProps[q && q[0]]} logo={q && logos[q[0]]} to={q ? `/q/${q[0]}/` : '/'} />}
      <Flex as="main" pt={!hideHeader && headerHeight} flex="1" flexDirection="column" overflow="hidden">
        {q && q[1] && !isResult
          ? <FormController pageContext={pageContext} state={location.state} quiz={q[0]} type={q[1]}>{children}</FormController>
          : <ThemeController quiz={q?.[0]} type={q?.[1]}>{children}</ThemeController>
        }
      </Flex>
      {!hideFooter && (!q || isResult) && <Footer {...footerProps[q && q[0]]} />}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
