import React from 'react'
import SVG from './SVG'

const Logo = (props) => {
  return (
    <SVG viewBox="0 0 60 48" {...props}>
      <g fill="currentColor">
        <g>
          <path d="M11.92,12l-2.48-.37c-1-.15-1.5-.66-1.5-1.54,0-1.21,1.06-1.81,3.18-1.81a6,6,0,0,1,2.65.46,1.57,1.57,0,0,1,.87,1.34H12.12c0-.37-.33-.55-1-.55s-.87.13-.87.39.09.26.28.28l2.59.39c1.18.17,1.77.7,1.77,1.6A1.58,1.58,0,0,1,14,13.67a5.69,5.69,0,0,1-2.68.48A6.77,6.77,0,0,1,9,13.84a1.56,1.56,0,0,1-1.13-1.53h2.61a.47.47,0,0,0,.34.45,2.46,2.46,0,0,0,.63.06c.57,0,.86-.15.86-.45S12.18,12,11.92,12Z" />
          <path d="M21,9.76V14H19.15v-.47h0a1.55,1.55,0,0,1-.6.43,2.88,2.88,0,0,1-1.13.21,2.1,2.1,0,0,1-1.33-.38,1.52,1.52,0,0,1-.53-1.26V9.76h1.92V12a.66.66,0,0,0,.74.76c.59,0,.89-.34.89-1v-2Z" />
          <path d="M21.93,15.47V9.76h1.86v.5h0a2.16,2.16,0,0,1,1.72-.62,2.49,2.49,0,0,1,1.66.54,2.08,2.08,0,0,1,.73,1.71,2.07,2.07,0,0,1-.73,1.7,2.51,2.51,0,0,1-1.66.54,2.1,2.1,0,0,1-1.68-.59v1.93Zm1.92-3.58c0,.63.35.95,1.06.95a1.08,1.08,0,0,0,.79-.28.9.9,0,0,0,.26-.67c0-.64-.35-1-1.06-1S23.85,11.25,23.85,11.89Z" />
          <path d="M32.52,12.7h2a1.76,1.76,0,0,1-1,1.05,4.65,4.65,0,0,1-2,.38c-2.12,0-3.18-.76-3.18-2.27a2,2,0,0,1,.79-1.68,3.65,3.65,0,0,1,2.21-.59c2.16,0,3.22.91,3.2,2.72H30.36a.64.64,0,0,0,.26.49,1.38,1.38,0,0,0,.9.27A1.19,1.19,0,0,0,32.52,12.7Zm-2.16-1.36h2.29a.7.7,0,0,0-.34-.54,1.46,1.46,0,0,0-.8-.2C30.84,10.6,30.45,10.85,30.36,11.34Z" />
          <path d="M35.3,14V9.76h1.86v.8h0A2.18,2.18,0,0,1,39,9.64a4.46,4.46,0,0,1,.63,0v1.72a4.35,4.35,0,0,0-1-.15c-.94,0-1.42.37-1.42,1.13V14Z" />
        </g>
        <g>
          <path d="M14.66,23.08,13.45,22a7.14,7.14,0,0,1-1.64.15,5.48,5.48,0,0,1-2.36-.4,2.56,2.56,0,0,1-1.39-2.53,2.57,2.57,0,0,1,1.39-2.54,5.48,5.48,0,0,1,2.36-.4,5.53,5.53,0,0,1,2.37.4,2.57,2.57,0,0,1,1.39,2.54,2.7,2.7,0,0,1-.81,2.14l.81.72Zm-2.93-2.66.89-1,.53.47a2.18,2.18,0,0,0,.11-.72c0-1-.48-1.56-1.45-1.56s-1.44.52-1.44,1.56.48,1.55,1.44,1.55l.27,0Z" />
          <path d="M21.74,17.75V22H19.88v-.47h0a1.44,1.44,0,0,1-.6.42,2.79,2.79,0,0,1-1.14.22,2.14,2.14,0,0,1-1.33-.38,1.55,1.55,0,0,1-.53-1.26V17.75h1.93V20c0,.5.24.76.73.76s.89-.34.89-1v-2Z" />
          <path d="M22.66,17.33V16.27h1.92v1.06Zm0,4.62v-4.2h1.92V22Z" />
          <path d="M25.23,22V20.88L27.61,19H25.37V17.75h4.82v1.1l-2.25,1.76h2.32V22Z" />
        </g>
        <path d="M58.19,34.9l-1.62.15V18.28H52.73a6.55,6.55,0,0,0,3.7-1.44c.86-.85,1.29-2.26,1.29-4.32V2.41H27.14V3.5H22.65V1.32H12.31V3.5h-9v9.83H2.48V23.44H3.86v.24c0,8.13-1,14.67-2.84,19.45l-.25.64.67.13c1.75.34,3.31.69,4.66,1a22.39,22.39,0,0,1,3.84,1.34l.61.28.17-.65A36.52,36.52,0,0,0,11.54,42a32.25,32.25,0,0,0,7.68,2.67A64.43,64.43,0,0,0,29.7,46c4.38.19,8.66.29,12.73.29S51,46.17,56,46l.48,0,.07-.48c.3-2.27.59-4.11.85-5.48a20.86,20.86,0,0,1,1.4-4.31l.39-.89Z" fill="none" stroke="currentColor" strokeWidth="0.67" />
      </g>
    </SVG>
  )
}

export default Logo
