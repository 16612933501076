import { Text } from '@chakra-ui/layout'
import { Icon, IconButton, useConst } from '@chakra-ui/react'
import { AiOutlineMail } from "react-icons/ai";
import React from 'react'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Link from '../../components/Link'
import Logo from '../../components/Logo'
import { Media, responsive } from '../../components/ThemeProvider/theme'

const Footer = (props) => {
  const y = useConst(() => new Date().getFullYear())
  return (
    <Box id="footer" as="footer" pos="relative" zIndex="sticky" color="white" bg="black" px={responsive('1.375em', '4.25em')} pt={responsive('1.25em', '3em')} pb={responsive('0.625em', '2em')}>
      <Flex justifyContent={'space-between'} alignItems="flex-end" pb={responsive('1.5em', null, 0)} borderBottom={responsive('2px solid white', null, 'none')} {...props}>
        <Flex alignItems={'flex-end'}>
          <Link to="/">
            <Logo width={responsive('3.375em', '8em')} />
          </Link>
          <Box fontSize={responsive('1.25em', '3.125em')} width="1em" ml={responsive('0.125rem', '0.5rem')} mr={responsive('0.5rem', '1.25rem')} lineHeight={1}>測驗</Box>
          <Text mb="-0.25em" fontSize={responsive('0.75em', '1.875em')} >Do Super Quiz <br/>Become SuperHuman</Text>
        </Flex>
        <Box textAlign={'right'}>
          <IconButton width={responsive('2.5em', '4.125em')} height={responsive('2.5em', '4.125em')} isRound border="1px solid white" icon={<Icon as={AiOutlineMail} w={responsive(4, 9)} h={responsive(4, 9)} />} />
          <Media greaterThanOrEqual={'laptop'}>
            <Text fontSize={'1.25em'} mt="1em">Copyright © {y} <Link color="inherit" to="/">Superquiz</Link></Text>
          </Media>
        </Box>
      </Flex>
      <Media lessThan={'laptop'}>
        <Text textAlign={'center'} fontSize={responsive('0.75em', '1.25em')} mt="1em">Copyright © {y} <Link color="inherit" to="/">Superquiz</Link></Text>
      </Media>
    </Box>
  )
}

export default Footer
