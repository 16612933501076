import { createMedia } from '@artsy/fresnel'
import { isArray, isNil, get } from 'lodash'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = [0, 30, 48, 62, 80, 96].map((em) => em * 16)

const chakraBpNames = ['sm', 'md', 'lg', 'xl', '2xl']
const chakraBps = chakraBpNames.reduce((bps, name, i) => {
  bps[name] = `${breakpoints[i + 1]}px`
  return bps
}, {})

export const responsiveIndex = [
  // [1, 'mini-mobile'],
  [2, 'mobile'],
  [3, 'tablet'],
  [4, 'laptop'],
  [5, 'desktop'],
]

const mediaBreak = responsiveIndex.reduce((obj, [i, name], j) => {
  obj[name] = j ? breakpoints[i - 1] : 0
  return obj
}, {})

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

const AppMedia = createMedia({ breakpoints: mediaBreak })
export const mediaStyle = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia

const handleCalc = (syn, a) => (isNil(a) ? null : [syn[0], a, syn[1]].join(''))

export const responsiveCalc = (syn, resArr) => {
  return isArray(resArr)
    ? resArr.map((a) => handleCalc(syn, a))
    : handleCalc(syn, resArr)
}

export const responsive = (...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
}

export const mobileOrDesktop = responsive

export const containerWidth = responsive('26em', '44em', '58em', '76em')
export const containerPadding = responsive('1em', '2em')

const font =
  'Raleway, "Noto Sans TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif'
const fonts = {
  heading: font,
  body: font,
  noto: '"Noto Sans TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif',
  mono: 'Menlo, monospace',
  dokdo: '"Dokdo", cursive',
}

const overrides = {
  fonts,
  styles: {
    global: {
      '#header': {
        bg: 'white',
      },
      '#footer': {
        letterSpacing: '0.1em',
      },
      pre: {
        p: '1em',
        bg: '#eee',
      },
      a: {
        color: 'orange.500',
      }
    },
  },
  colors: {
    flower: {
      bg: {
        400: '#fff',
      },
      text: '#717f01',
      buttonBg: '#8a9800',
      number: '#3c3c3c',
      lightGray: '#eeeeee',
      darkGray: '#4d4d4d',
      anaBg: '#f2f3e8',
      lightRed: '#df5f36',
    },
    love: {
      yellow: '#FEFAE9',
      headerYellow: '#FFEFB7',
      orange: '#E68F34',
      titleOrange: '#6A3906',
      deepOrange: '#CC7F2E',
      beige: '#FDF9EB',
      red: '#D9796D',
      deepRed: '#73281F',
      descRed: '#E07D71',
      borderRed: '#C7493D',
      darkRed: '#871411',
      pink: '#FCDFD6',
      footerPink: '#FFF1EA',
      footerGreen: '#E9F4E7',
      lightPink: '#FCF6F2',
      shiningPink: '#ED766C',
      green: '#669933',
      lightGreen: '#F2F7ED',
      shiningGreen: '#57B25B',
      titleGreen: '#6FB265',
      descGreen: '#57B25B',
      borderGreen: '#3A763D',
      deepGreen: '#244825',
      darkGreen: '#144921',
      brown: '#A66924',
      resultFriendBg: '#D9EFC9',
    },
    custom: {
      coffee: '#663333',
      lightGray: '#F7F8F8',
      lightYellow: '#FFF9E8',
      multiGray: '#757575',
      black: { 500: '#000' },
      darkPurple: '#6C4645',
      lightPurple: '#AA7C72',
      lightPink: '#E8BFB0',
      bgPink: '#A97C72',
    },
  },
  sizes: {
    headerHeight: '4em',
  },
  breakpoints: createBreakpoints(chakraBps),
  components: {
    Text: {
      variants: {
        'women-title': {
          lineHeight: 1,
          color: 'white',
          textShadow: '1px 3px 12px #671D1D',
          fontSize: responsive('1.875em', '3em'),
          letterSpacing: '0.125rem',
          fontWeight: 'bold',
          fontFamily: 'Noto Serif TC',
        },
      },
    },
    // Link: {
    //   baseStyle: {
    //     color: 'teal.500',
    //   },
    // },
    Button: {
      defaultProps: {
        colorScheme: 'orange',
      },
      variants: {
        women: {
          bg: 'rgba(158, 22, 22, 0.35)',
          color: 'white',
          height: 'auto',
          border: '2px solid',
          borderRadius: 'full',
          letterSpacing: '0.125rem',
          px: responsive('2.25rem', '1em'),
          py: responsive('0.875rem', '1em'),
          _hover: { bg: 'rgba(158, 22, 22, 0.35)' },
          textShadow: '1px 3px 12px #952C2A',
          fontSize: responsive('1.125em', '1.5em'),
          fontWeight: '500',
        },
      },
    },
    Box: {
      variants: {
        fullAbs: {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: 'container.md',
      },
      variants: {
        women: {
          px: responsive('3em', '5em'),
          maxW: 'auto',
        },
        'women-block': {
          pos: 'relative',
          minHeight: '100vh',
          pb: responsive('3.875em'),
          px: 0,
          maxW: 'auto',
        },
      },
    },
  },
}

export default overrides
