import React, { cloneElement } from 'react'
import { IconButton, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { RiHome4Line, RiArticleLine } from 'react-icons/ri'

import Box from '../components/Box';
import Flex from '../components/Flex';
import Link from '../components/Link';
import { responsive } from '../components/ThemeProvider/theme';
// import ReLink from '../components/Link';

import Logo from '../components/Logo';
import SVG from '../components/SVG';

import engTitle from './eng-title.svg';

const links = [
  { to: '/', label: '返回首頁', icon: <RiHome4Line /> },
  { to: '/articles', label: '文章列表', icon: <RiArticleLine /> },
]

const MenuIcon = props => (
  <SVG viewBox="0 0 60 60" {...props}>
    <g>
      <line stroke="currentColor" x1="12.58" y1="16.21" x2="47.42" y2="16.21" />
      <line stroke="currentColor" x1="12.58" y1="30" x2="47.42" y2="30" />
      <line stroke="currentColor" x1="12.58" y1="43.79" x2="47.42" y2="43.79" />
    </g>
  </SVG>
)

const TheMenu = () => {
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<MenuIcon />} size="lg" variant="ghost" color="black" />
      <MenuList fontSize="lg">
        {links.map(({ to, label, icon }) => (
          <MenuItem as={Link} to={to}>
            <Box mr="2">
              {cloneElement(icon, { size: '1.125em' })}
            </Box>
            <span>
              {label}
            </span>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const Header = ({ hideLinks, logo, to = '/', ...props }) => (
  <Flex
    id="header"
    as="header"
    position="fixed"
    top={0}
    left={0}
    right={0}
    alignItems="center"
    justifyContent="space-between"
    zIndex="99"
    borderBottom="1px solid"
    borderBottomColor="blackAlpha.700"
    px="4"
    {...props}
  >
    <Box width={logo ? responsive('8em', '9em') : '3.25em'}>
      <Link to={to} color="inherit" _hover={{ color: 'inherit' }}>
        {logo || <Logo />}
      </Link>
    </Box>
    <Box flex="1">
      {!logo && (
        <Box pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" display={responsive('none', 'block')}>
          <Link to="/">
            <Image w="15em" src={engTitle} alt="Super Quiz" />
          </Link>
        </Box>
      )}
    </Box>
    <Box>
      <TheMenu />
    </Box>
  </Flex>
)

export default Header
